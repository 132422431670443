import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Loading from "../components/loading/Loading";
import { useAuth } from "../utils/AuthContext";
import { Quiz } from "@common/models";
import {
  deleteQuizWithCache,
  getQuizzesWithCache,
  upsertQuizWithCache,
} from "@/utils/quiz-dao";
import { Helmet } from "react-helmet";
import { TableCellsIcon, ListBulletIcon } from "@heroicons/react/24/solid";
import { QuizCompactView } from "../components/QuizCompactView";
import { CollectionCardView } from "../components/CollectionCardView";

const CollectionPage: React.FC = () => {
  const { user, isLoading: isAuthLoading } = useAuth();
  const [groupedQuizzes, setGroupedQuizzes] = useState<{ [key: string]: Quiz[] }>({});
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [viewMode, setViewMode] = useState<"grouped" | "compact">("compact");

  useEffect(() => {
    const initialize = async () => {
      if (user) {
        const allQuizzes: Quiz[] = await getQuizzesWithCache(user);
        groupQuizzesByDate(allQuizzes);
      }
      setIsLoading(false);
    };

    if (!isAuthLoading) {
      initialize();
    }
  }, [user, isAuthLoading]);

  const groupQuizzesByDate = (quizzes: Quiz[]) => {
    const sortedQuizzes = quizzes.sort(
      (a, b) => new Date(b.creationTime!).getTime() - new Date(a.creationTime!).getTime()
    );
    const grouped = sortedQuizzes.reduce((acc: { [key: string]: Quiz[] }, quiz: Quiz) => {
      const date: string = new Date(quiz.creationTime!).toLocaleDateString();
      acc[date] = acc[date] ? [...acc[date], quiz] : [quiz];
      return acc;
    }, {});
    setGroupedQuizzes(grouped);
  };

  async function handleQuizUpdated(quiz: Quiz) {
    await upsertQuizWithCache(user!, quiz);
  }

  const handleQuizDeleted = async (quizId: string) => {
    await deleteQuizWithCache(user!, quizId);
    const updatedGroupedQuizzes = { ...groupedQuizzes };
    for (const date in updatedGroupedQuizzes) {
      updatedGroupedQuizzes[date] = updatedGroupedQuizzes[date].filter(
        (quiz) => quiz.id !== quizId
      );
      if (updatedGroupedQuizzes[date].length === 0) {
        delete updatedGroupedQuizzes[date];
      }
    }
    setGroupedQuizzes(updatedGroupedQuizzes);
  };

  if (isLoading || isAuthLoading) {
    return (
      <div className="min-h-screen flex justify-center">
        <Loading size="large" />
      </div>
    );
  }

  if (!user) {
    navigate("/signin");
    return null;
  }

  return (
    <>
      <Helmet>
        <title>Collection</title>
      </Helmet>
      <div className="container mx-auto py-8 px-4 bg-gradient-to-b from-indigo-50 to-white">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-bold text-indigo-900">Your Collection</h1>
          <div className="flex gap-2">
            <button
              onClick={() => setViewMode("grouped")}
              className={`p-2 rounded ${
                viewMode === "grouped"
                  ? "bg-indigo-600 text-white"
                  : "bg-gray-200 text-gray-600"
              }`}
              title="Grouped View">
              <TableCellsIcon className="h-5 w-5" />
            </button>
            <button
              onClick={() => setViewMode("compact")}
              className={`p-2 rounded ${
                viewMode === "compact"
                  ? "bg-indigo-600 text-white"
                  : "bg-gray-200 text-gray-600"
              }`}
              title="List View">
              <ListBulletIcon className="h-5 w-5" />
            </button>
          </div>
        </div>

        {Object.keys(groupedQuizzes).length === 0 ? (
          <div className="text-center">
            <p className="text-xl text-gray-700 mb-4">
              You don't have any flashcards yet.
            </p>
            <Link
              to="/"
              className="inline-block bg-indigo-600 text-white font-semibold px-6 py-3 rounded-full hover:bg-indigo-700 transition-colors">
              Start Creating One
            </Link>
          </div>
        ) : viewMode === "grouped" ? (
          <CollectionCardView
            groupedQuizzes={groupedQuizzes}
            onQuizUpdated={handleQuizUpdated}
            onQuizDeleted={handleQuizDeleted}
          />
        ) : (
          <QuizCompactView
            groupedQuizzes={groupedQuizzes}
            onQuizUpdated={handleQuizUpdated}
            onQuizDeleted={handleQuizDeleted}
          />
        )}
      </div>
    </>
  );
};

export default CollectionPage;
