import React from "react";
import { Quiz } from "@common/models";
import { QuizEntry } from "./QuizEntry";

interface CollectionCardViewProps {
  groupedQuizzes: { [key: string]: Quiz[] };
  onQuizUpdated: (quiz: Quiz) => Promise<void>;
  onQuizDeleted: (quizId: string) => Promise<void>;
}

export const CollectionCardView: React.FC<CollectionCardViewProps> = ({
  groupedQuizzes,
  onQuizUpdated,
  onQuizDeleted,
}) => {
  return (
    <div className="space-y-8">
      {Object.keys(groupedQuizzes).map((date, index) => (
        <div key={index} className="bg-white rounded-lg shadow-md overflow-hidden">
          <h2 className="bg-indigo-100 text-indigo-800 text-xl font-semibold py-3 px-6">
            {date}
          </h2>
          <div className="divide-y divide-gray-200">
            {groupedQuizzes[date].map((quiz, quizIndex) => (
              <div key={`${quiz.id}`} className="p-4">
                <QuizEntry
                  quiz={quiz}
                  index={quizIndex}
                  isInCollections={true}
                  allowDelete={true}
                  onQuizUpdated={onQuizUpdated}
                  onQuizDeleted={onQuizDeleted}
                />
              </div>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
}; 