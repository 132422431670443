import React, { useState } from "react";
import { Quiz } from "@common/models";
import { QuizEntry } from "./QuizEntry";
import { QuizDetailCard } from "./QuizDetailCard";

interface QuizCompactViewProps {
  groupedQuizzes: { [key: string]: Quiz[] };
  onQuizUpdated: (quiz: Quiz) => Promise<void>;
  onQuizDeleted: (quizId: string) => Promise<void>;
}

export const QuizCompactView: React.FC<QuizCompactViewProps> = ({
  groupedQuizzes,
  onQuizUpdated,
  onQuizDeleted,
}) => {
  const [selectedQuiz, setSelectedQuiz] = useState<Quiz | null>(null);
  const [localQuizzes, setLocalQuizzes] = useState(groupedQuizzes);

  const handleQuizClick = (quiz: Quiz) => {
    setSelectedQuiz(quiz);
  };

  const handleCloseModal = () => {
    setSelectedQuiz(null);
  };

  const handleQuizUpdated = async (updatedQuiz: Quiz) => {
    await onQuizUpdated(updatedQuiz);
    
    setSelectedQuiz(updatedQuiz);
    
    setLocalQuizzes(prevQuizzes => {
      const newQuizzes = { ...prevQuizzes };
      
      Object.keys(newQuizzes).forEach(key => {
        const index = newQuizzes[key].findIndex(q => q.id === updatedQuiz.id);
        if (index !== -1) {
          newQuizzes[key] = [
            ...newQuizzes[key].slice(0, index),
            updatedQuiz,
            ...newQuizzes[key].slice(index + 1)
          ];
        }
      });
      
      return newQuizzes;
    });
  };

  const handleDelete = async (quiz: Quiz, e: React.MouseEvent) => {
    e.stopPropagation();
    await onQuizDeleted(quiz.id);
    
    setLocalQuizzes(prevQuizzes => {
      const newQuizzes = { ...prevQuizzes };
      Object.keys(newQuizzes).forEach(key => {
        newQuizzes[key] = newQuizzes[key].filter(q => q.id !== quiz.id);
      });
      return newQuizzes;
    });
  };

  const handleDetailDelete = async (quiz: Quiz) => {
    await onQuizDeleted(quiz.id);
    
    setLocalQuizzes(prevQuizzes => {
      const newQuizzes = { ...prevQuizzes };
      Object.keys(newQuizzes).forEach(key => {
        newQuizzes[key] = newQuizzes[key].filter(q => q.id !== quiz.id);
      });
      return newQuizzes;
    });
    handleCloseModal();
  };

  return (
    <>
      <div className="bg-white rounded-lg shadow-md overflow-hidden">
        <div className="grid grid-cols-12 bg-indigo-100 text-indigo-800 font-semibold py-3 px-6">
          <div className="col-span-6">Question</div>
          <div className="col-span-4">Created</div>
          <div className="col-span-2">Actions</div>
        </div>
        <div className="divide-y divide-gray-200">
          {Object.values(localQuizzes)
            .flat()
            .sort(
              (a, b) =>
                new Date(b.creationTime!).getTime() -
                new Date(a.creationTime!).getTime()
            )
            .map((quiz) => (
              <div
                key={quiz.id}
                data-testid={`quiz-row-${quiz.id}`}
                className="grid grid-cols-12 items-center py-3 px-6 hover:bg-gray-50 cursor-pointer"
                onClick={() => handleQuizClick(quiz)}>
                <div className="col-span-6 font-medium text-gray-900">
                  {quiz.question}
                </div>
                <div className="col-span-4 text-gray-600">
                  {new Date(quiz.creationTime!).toLocaleDateString()}
                </div>
                <div className="col-span-2">
                  <button
                    onClick={(e) => handleDelete(quiz, e)}
                    className="px-3 py-1 bg-red-100 text-red-600 rounded hover:bg-red-200 text-sm"
                    data-testid={`delete-quiz-${quiz.id}`}
                  >
                    Delete
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>

      {/* Quiz Detail Modal */}
      {selectedQuiz && (
        <div 
          data-testid="modal-overlay"
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={handleCloseModal}
        >
          <div 
            className="bg-white rounded-lg p-6 max-w-2xl w-full mx-4 max-h-[90vh] overflow-y-auto"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-xl font-bold text-gray-900">Flashcard Details</h2>
              <button
                onClick={handleCloseModal}
                className="text-gray-500 hover:text-gray-700">
                <span className="text-2xl">&times;</span>
              </button>
            </div>
            <QuizDetailCard 
              quiz={selectedQuiz} 
              onQuizUpdated={handleQuizUpdated}
              onQuizDeleted={handleDetailDelete}
            />
          </div>
        </div>
      )}
    </>
  );
}; 